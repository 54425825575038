import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import { validateDag } from 'store/airflow.js'
import './DagValidate.scss'

export default class DagValidate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validation: false,
      validationError: false,
    }
  }

  componentDidMount() {
    if (this.props.cluster.airflow_version === '1.10.4-python36') {
      this.setState({
        validation:
          'DAG Validation not supported for 1.10.4-python36 , Upgrade to newest version',
        validationError: false,
      })
    } else {
      validateDag(this.props.region.value, this.props.cluster.cluster_name, {
        file_name: this.props.file_name,
        encoded_content: this.props.encoded_content,
      })
        .then(data =>
          this.setState({
            validation: data.output.stdout,
            validationError: false,
          })
        )
        .catch(err =>
          this.setState({
            validation: false,
            validationError: err?.map(item => item.message),
          })
        )
    }
  }

  render() {
    let { submitText, onSubmit, onClose } = this.props
    let { validation, validationError } = this.state
    let body = <Spinner />

    if (validation) {
      body = (
        <div className='dag-validation-body'>
          {validation.split('\n').map(
            (line, i) =>
              line && (
                <p className='dag-validation-line' key={i}>
                  {line}
                </p>
              )
          )}
        </div>
      )
    }

    if (validationError) {
      const listErrors = validationError.map((item, index) => {
        return (
          <span key={index}>
            {item}
            {index + 1 < validationError.length ? ', ' : ''}
          </span>
        )
      })

      body = (
        <p className='dag-validation-error'>
          <i className='fa fa-exclamation-triangle' />
          <strong>
            The DAG failed to Validate with the following errors:{' '}
          </strong>
          {listErrors}
        </p>
      )
    }

    return (
      <Dialogue
        className='dag-validation'
        title='DAG VALIDATION'
        subtitle={
          <span>
            Attempts to validate the DAG contents against the provisioned
            airflow webserver.
            <br />
            This process may fail if the airflow webserver is unavailable and is
            not required to submit.
            <br />
            <br />
            {this.props.cerberus === true && (
              <>
                <i className='fa fa-exclamation-triangle'></i> You are using a{' '}
                <span className='cerberus-alert'>Cerberus Client</span>. Please
                refer to{' '}
                <a href='https://docs.platforms.nike.com/managed-airflow-platform/airflow-basics/airflow-best-practices'>
                  MAP Documentation
                </a>{' '}
                for our best practices.
              </>
            )}
          </span>
        }
        onConfirm={onSubmit}
        confirmText={submitText}
        onClose={onClose}
        closeText='CLOSE'
      >
        {body}
      </Dialogue>
    )
  }
}
